.weekly-view {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2.5rem 1rem 2.5rem;
}

.weekly-view-day-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

@media screen and (max-width: 648px) {
  .weekly-view-day-container {
    flex-direction: column;
  }
}
