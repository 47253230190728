:root,
:host {
  --spacing-s: 0.5rem;
  --spacing-m: 1rem;
  --spacing-l: 2rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.basic-text {
  font-family: Monaco, source-code-pro, Menlo, Consolas, 'Courier New', monospace;
  font-size: 1.5em;
  font-weight: bold;
  color: #eb539f;
  margin: 0;
}

@media screen and (max-width: 900px) {
  .mobile-hidden {
    display: none;
  }
}
