.admin-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.admin-link-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  margin: 0 var(--spacing-l);
}

.admin-common-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
}

.admin-common-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  margin: auto;
  text-align: start;
}

.admin-management-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.admin-modal-close-btn {
  position: absolute;
  top: var(--spacing-m);
  right: var(--spacing-m);
}

.admin-modal-container {
  display: flex;
  padding: var(--spacing-m);
  background-color: black;
  border: 1px solid var(--bs-primary);
  border-radius: 8px;
}

.admin-form-full-width {
  width: 100%;
}

.admin-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.admin-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  width: 100%;
}
