.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin: 1rem 0.5rem 0.5rem 0;
}

.footer-text {
  font-family: Monaco, source-code-pro, Menlo, Consolas, 'Courier New', monospace;
  font-size: 1em;
  font-weight: bold;
  color: #eb539f;
  margin: 0;
}

@media screen and (max-width: 580px) {
  .footer {
    position: relative;
  }
}